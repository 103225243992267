import * as Sentry from "@sentry/browser"
import { BrowserTracing } from '@sentry/tracing'
import { assert } from "../lib/assert"
import { RELEASE } from "../config"

window.sentry = Sentry

export function setupSentry({ dsn, Vue } = {}) {
  assert(dsn)
  assert(Vue)
  Sentry.init({
    dsn,
    release: RELEASE,
    integrations: [new BrowserTracing()],
  })
}
